<template>
    <v-card outlined>
        <v-card-title class="grey lighten-3 text-xs-right">
            <h5 class="title primary--text text-xs-right">MODULO CERRAR CAJA</h5>
        </v-card-title>
        <v-card-text class="grey lighten-5">
            <h5>DESEA CERRAR LA CAJA DEL DÍA?</h5>
        </v-card-text>

        <v-card-actions>
            <v-row dense>
                <v-col cols="6" class="pa-1">
                    <v-btn text block @click="UPDATE_CERRAR_CAJA">ATRAS</v-btn>
                </v-col>
                <v-col cols="6" class="pa-1">
                    <v-btn color="primary" dark block :loading="loading" @click.native="postCaja">CERRAR CAJA</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>
<script>
import { mapState } from 'vuex'
import { mixins } from '@/mixins/mixin.js'

export default {
    $_veeValidate: {
        validator: 'new'
    },
    mixins: [mixins],
    data: () => ({
        loading: false,
        form: {
            monto: null,
            detalle: null,
            key: Date.now()
        },
        listaGastos: []
    }),
    computed: {
        ...mapState('utilities', ['listaCategoria']),

        montoTotal() {
            if (this.listaGastos.length > 0) {
                let suma = 0
                this.listaGastos.map((val) => (suma += parseFloat(val.monto)))
                return suma.toFixed(2)
            }
            return 0
        }
    },
    methods: {
        UPDATE_CERRAR_CAJA() {
            this.$store.commit('caja/UPDATE_CERRAR_CAJA', false)
        },
        async postCaja() {
            this.loading = true
            for (const item of this.listaGastos) {
                const name = this.listaCategoria.find((v) => v.id_tipo_gasto == item.id_tipo_gasto.id_tipo_gasto)

                await this.$store.dispatch('gastos/postGastos', {
                    importe: item.monto,
                    descripcion: item.detalle,
                    id_tipo_gasto: item.id_tipo_gasto.id_tipo_gasto,
                    tipo_gasto: name.tipo_gasto
                })
            }
            await this.$store.dispatch('caja/cierreCaja')
            await this.$store.dispatch('caja/getCajaDia')
            this.UPDATE_CERRAR_CAJA()
            this.$store.commit('caja/UPDATE_OPEN_CAJA', true)
            this.loading = false
            this.$router.push('/gestion/caja')
        },
        addGastos() {
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    this.listaGastos.push(this.form)
                    this.form = {
                        monto: null,
                        detalle: null,
                        key: Date.now()
                    }
                }
            })
        },
        deleteItem(item) {
            this.listaGastos.splice(item, 1)
        }
    }
}
</script>
