<template>
    <div>
        <!-- <v-card class="card--flex-toolbar stickyToolbar"> -->
        <v-card class="card--flex-toolbar">
            <v-app-bar flat color="grey lighten-2" class="hidden-xs-only">
                <v-chip color="grey darken-1" text-color="white">
                    <v-icon>mdi-map-marker</v-icon>&nbsp; <strong>{{ sede.province }}</strong
                    >&nbsp;
                    <span>{{ sede.direccion }}</span>
                </v-chip>
                <v-spacer></v-spacer>
            </v-app-bar>
        </v-card>
        <v-container v-if="!loading" class="mainRender white" fluid>
            <v-row dense>
                <v-col cols="12" md="8" xl="10" order-xs2 order-md1 v-if="![2,4].includes(user.idBussines)">
                    <v-row dense>
                        <v-col cols="12" md="7" xl="8" v-if="![2,4].includes(user.idBussines)">
                            <v-card outlined>
                                <v-card-title class="grey lighten-3">
                                    <h5>Control Sauna Mixto</h5>
                                    <v-spacer />
                                    <h5>S/.{{ cajaDia.totalmixtos }}</h5>
                                </v-card-title>
                                <v-card-text>
                                    <v-row dense>
                                        <v-col v-for="(item, key) in listaMixtosToday" :key="key" cols="6" sm="4" md="3" xl="2">
                                            <RoomApp :item="item" :key="key" />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="5" xl="4" v-if="![2,4].includes(user.idBussines)">
                            <v-row dense>
                                <v-col cols="12" sm="6" md="12">
                                    <v-card outlined>
                                        <v-card-title class="grey lighten-3">
                                            <h5>Control Privados</h5>
                                            <v-spacer />
                                            <h5>S/. {{ cajaDia.totalprivado }}</h5>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row dense>
                                                <v-col v-for="(item, key) in listaPrivadosToday" :key="key" cols="6" md="4" xl="4">
                                                    <RoomApp :item="item" :key="key" />
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                    <v-card outlined>
                                        <v-card-title class="grey lighten-3">
                                            <h5>Control Masajes</h5>
                                            <v-spacer />
                                            <h5>S/. {{ cajaDia.totalmass }}</h5>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row dense>
                                                <v-col v-for="(item, key) in listaMasajesToday" :key="key" cols="6" md="4" lg="4" xl="4">
                                                    <RoomApp :item="item" :key="key" />
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <!--  <v-col cols="12" sm="6" md="12">
                                    <v-card outlined min-height="100">
                                        <v-card-title class="grey lighten-3">
                                            <h5>Control Productos</h5>
                                            <v-spacer /> S/.
                                            <h5>S/. {{ cajaDia.totalproductos }}</h5>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row dense>
                                                <v-col v-for="(item, key) in listaProductosToday" :key="key" cols="2" class="pa-1">
                                                    <v-chip dark style="fontSize:10px !important" color="#FF6B3C">
                                                        {{
                                                            item.product
                                                                .slice(0, 2)
                                                                .concat('...')
                                                                .toLowerCase()
                                                        }}
                                                    </v-chip>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col> -->
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-else>
            <v-progress-linear indeterminate value="15"></v-progress-linear>
        </v-container>
        <ActionDial></ActionDial>

        <v-dialog v-model="closeCaja" max-width="800" persistent transition="dialog-bottom-transition">
            <CerrarCaja></CerrarCaja>
        </v-dialog>
        <v-dialog v-model="modalReserva.state" scrollable max-width="1024" persistent>
            <ModalReserva v-if="modalReserva.state" />
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { compareAsc, parseISO, addMinutes } from 'date-fns'
import RoomApp from '@/components/card/RoomApp'
import ActionDial from '@/components/ActionDial'
import CerrarCaja from '@/components/caja/CerrarCaja'
import ModalReserva from '@/components/modals/ModalReserva'
import { formatNumber } from '@/utils/genericUtils'

export default {
    components: {
        RoomApp,
        ModalReserva,
        CerrarCaja,
        ActionDial
    },
    data: () => ({
        fab: false,
        loading: false,
        loadingcaja: false,
        modal: false,
        cerrarCaja: true,
        price: null,
        description: null,
        datenow: ''
    }),
    computed: {
        ...mapState('ventas', ['modalReserva', 'privadosToday', 'masajesToday', 'mixtosToday', 'productosToday', 'listaCalendarioToday']),
        ...mapState('caja', ['cajaDia', 'opencaja', 'closeCaja']),
        ...mapGetters('productos', ['listaProductosOut', 'listaProductos', 'listaCortesiaOut']),
        ...mapGetters('ventas', ['listaItemMixto', 'listaItemMasajes', 'listaItemPrivados']),

        ...mapState('mixtos', ['listaMixto']),
        ...mapState('privados', ['listaHab']),
        ...mapState('masajes', ['listaMassage']),
        ...mapState('sedes', ['sede']),
        ...mapState('users', ['user']),
        ...mapState('utilities', ['listaCategoria']),
        totalGastos() {
            let suma = 0
            this.listaGastos.map((v) => {
                suma += v.importe
            })
            return suma
        },
        listaPrivadosToday() {
            // FILTER BY TIME && DAY

            const lista = []

            this.listaHab.map((val) => {
                let isData = []

                this.listaItemPrivados.map((el) => {
                    const result = compareAsc(addMinutes(parseISO(el.check_out_time), 240), new Date())
                    const result1 = compareAsc(parseISO(el.check_out_time), new Date())
                    //                                        const idRoom = el.idRoom ? el.idRoom : null
                    const idRoom = el.idRoom ? el.idRoom : null

                    const result2 = compareAsc(new Date(), parseISO(el.check_in_time))

                    if (idRoom == val.id && result > 0 && result2 > 0) {
                        let showFinalizado = false
                        if (result1 < 0) {
                            showFinalizado = true
                        }
                        isData.push({ ...el, showFinalizado })
                    }
                    return null
                })
                lista.push({ ...val, name: val.room, isData })
            })
            return lista
        },
        listaMixtosToday() {
            const lista = []

            this.listaMixto.map((val) => {
                let isData = []
                this.listaItemMixto.map((el) => {
                    const idRoom = el.idRoom ? el.idRoom : null

                    const result = compareAsc(addMinutes(parseISO(el.check_out_time), 240), new Date())
                    const result1 = compareAsc(parseISO(el.check_out_time), new Date())
                    const result2 = compareAsc(new Date(), parseISO(el.check_in_time))

                    if (idRoom == val.id && result > 0 && result2 > 0) {
                        let showFinalizado = false
                        if (result1 < 0) {
                            showFinalizado = true
                        }
                        isData.push({ ...el, showFinalizado })
                    } else {
                    }
                })
                const data = { ...val, name: val.mixto, isData }

                lista.push(data)
            })

            return lista
        },
        listaMasajesToday() {
            // FILTER BY TIME && DAY

            const lista = []
            this.listaMassage.map((val) => {
                let isData = []

                this.listaItemMasajes.map((el) => {
                    const result = compareAsc(addMinutes(parseISO(el.check_out_time), 240), new Date())

                    const result1 = compareAsc(parseISO(el.check_out_time), new Date())
                    //
                    const result2 = compareAsc(new Date(), parseISO(el.check_in_time))
                    const idRoom = el.idRoom ? el.idRoom : null

                    if (idRoom == val.id && result > 0 && result2 > 0) {
                        let showFinalizado = false
                        if (result1 < 0) {
                            showFinalizado = true
                        }
                        isData.push({ ...el, showFinalizado })
                    }
                    return null
                })
                lista.push({ ...val, name: val.massage, isData })
            })
            return lista
        }
    },
    watch: {
        datenow(val) {
            this.listaPrivadosToday
            this.listaMixtosToday
            this.listaMasajesToday
        }
    },
    async created() {
        // await this.$store.dispatch('sedes/getSede')
        this.loading = true
        //console.time('starter_services_promises')
        await this.$store.dispatch('productos/products', { active: 1 })
        const { idBussines } = this.user

        let items = [this.$store.dispatch('ventas/getPendientesReservas'), this.$store.dispatch('sedes/getDepartamento', { online: true })]
        if (idBussines == 1) {
            //items.push(this.$store.dispatch('ventas/lsVentaNoFinalizada'))
            items.push(this.$store.dispatch('privados/getTable', false))
            items.push(this.$store.dispatch('masajes/getTable', false))
            items.push(this.$store.dispatch('mixtos/getTable', false))
        }
        await Promise.all(items)
        this.loading = false
        //console.timeEnd('starter_services_promises')
    },
    async mounted() {
        this.interval = setInterval(this.time, 1000 * 60 * 25)
        await Promise.all([
            this.$store.dispatch('productos/products', { active: 1 }),
            this.$store.dispatch('clientes/getListaCli', { active: 1 }),
            this.$store.dispatch('privados/servicios', { active: 1 })
        ])
    },
    beforeDestroy() {
        clearInterval(this.interval)
    },
    methods: {
        isformatNumber(item, name) {
            const value = item[name]
            return formatNumber(value, item.idTipoMoneda)
        },
        time() {
            this.datenow = Date.now()
        },
        UPDATE_CERRAR_CAJA() {
            this.$store.commit('caja/UPDATE_CERRAR_CAJA', true)
        },
        OPEN_CAJA() {
            this.$store.commit('caja/UPDATE_OPEN_CAJA', false)
        }
    }
}
</script>
<style scoped>
.stickyToolbar {
    position: -webkit-sticky !important;
    position: -moz-sticky !important;
    position: -ms-sticky !important;
    position: -o-sticky !important;
    position: sticky !important;
    top: 64px !important;
    z-index: 2;
}
</style>
