<template>
    <div id="speeddial" class="speeddial animated swing">
        <v-speed-dial v-model="fab" open-on-hover direction="top" bottom right>
            <template v-slot:activator>
                <v-btn v-model="fab" color="primary" dark fab>
                    <v-icon v-if="fab" class="white--text">mdi-close</v-icon>
                    <v-icon v-else class="white--text">mdi-call-made</v-icon>
                </v-btn>
            </template>
            <v-btn rounded dark class="white--text" @click="openModal">Agregar Venta</v-btn>
            <v-btn rounded dark class="white--text" @click="UPDATE_CERRAR_CAJA">Cerrar CAJA</v-btn>                        
            <v-btn rounded dark class="white--text" to="/gestion/asistencia">Asistencia</v-btn>
        </v-speed-dial>
    </div>
</template>
<script>
export default {
    data() {
        return {
            fab: false
        }
    },
    methods: {
        openModal() {
            this.loading = true
            this.$store.commit('ventas/SHOW_MODAL', { step: 1, state: true })
            this.loading = false
        },
        UPDATE_CERRAR_CAJA() {
            this.$store.commit('caja/UPDATE_CERRAR_CAJA', true)
        }
    }
}
</script>

<style scoped>
.speeddial {
    position: fixed;
    bottom: 30px !important;
    right: 30px !important;
    text-align: center;
    z-index: 10000;
}
</style>
