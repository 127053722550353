<template>
    <v-row no-gutters :style="style" class="mainApp" @click="updateModal">
        <v-col cols="12" :class="classText" class="caption text-center">
            <b>{{ item.name }}</b>
        </v-col>
        <v-col cols="12" class="text-center">
            <template v-if="horaInicio">
                <v-row no-gutters>
                    <v-col v-if="!itemFound.showFinalizado" cols="12">
                        <v-row no-gutters style="position: relative !important">
                            <v-col cols="12" justify="center" align="center">
                                <v-icon dark style="font-size: 32px">mdi-lock</v-icon>
                            </v-col>
                            <v-col cols="12" class="mainPrice" justify="right" align="right">
                                <b class="yellow--text pa-2" style="font-size: 12px">S/. {{ itemFound.subTotal }}</b>
                            </v-col>
                            <v-col cols="12"
                                ><b class="yellow--text" style="font-size: 12px">{{ itemFound.full_name | smallName }}</b></v-col
                            >
                            <v-col cols="12">
                                <span class="body-2 white--text">
                                    {{ horaInicio }} -
                                    <b>{{ horaFin }}</b>
                                </span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-else cols="12">
                        <img src="../../assets/checkout.gif" style="width: 40px; height: 40px" />
                    </v-col>
                </v-row>
                <!-- <v-row no-gutters  v-if="!itemFound.showFinalizado" class="mainIcon" :style="mainStyle">
          <v-col class="mainPrice">
            <b class="yellow--text" style="font-size:12px">S/. {{ itemFound.subTotal }}</b>
          </v-col>
          <v-col class="mainName">
            <v-icon dark style="font-size:32px">mdi-lock</v-icon>
            <b class="yellow--text" style="font-size:12px">{{ itemFound.full_name | smallName }}</b>
            <span class="body-2">
              {{ horaInicio }} -
              <b>{{ horaFin }}</b>
            </span>
          </v-col>
        </v-row>
        -->
            </template>
            <v-icon v-else :class="classText" style="font-size: 35px">mdi-lock-open</v-icon>
        </v-col>
    </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { format, parseISO } from 'date-fns'

export default {
    filters: {
        smallName(val) {
            try {
                console.log('val ------', val)
                const pos = val.indexOf(' ')
                //console.log(`Pos:${pos}`)
                return val.slice(0, pos > 0 ? pos + 2 : val.length)
            } catch (error) {
                return ''
            }
        }
    },
    props: ['item', 'service'],
    data: () => ({
        modal: false,
        status: false,
        mainStyle: {
            'background-image': "url('../../assets/checkout.gif')"
        }
    }),
    computed: {
        ...mapState('ventas', ['datos']),
        itemFound() {
            const isFilter = this.item.isData.filter((val) => val)
            //console.log("isFilter ",isFilter)
            if (isFilter.length > 0) {
                /* //////console.log("filtrado"); */
                console.log('isFilter[0] 1', isFilter[0])
                return isFilter[0]
            }
            return null
        },
        horaInicio() {
            console.log('this.item.isData ', this.item)
            const isFilter = this.item.isData.filter((val) => val)
            console.log('isFilter ---> ', isFilter)
            if (isFilter.length > 0) {
                console.log('isFilter[0] 2', isFilter[0])
                const value = isFilter[0] ? format(parseISO(isFilter[0].check_in_time), 'HH:mm') : null
                console.log('value ', value)
                return value
            }
            return null
        },
        horaFin() {
            const isFilter = this.item.isData.filter((val) => val)
            /* //////console.log("lol"); */
            /*       //////console.log(isFilter);
             */ if (isFilter.length > 0) {
                /*      //////console.log("filtrado");9
        //////console.log(isFilter[0]); */
                return isFilter[0] ? format(parseISO(isFilter[0].check_out_time), 'HH:mm') : null
            }
            return null
        },
        color() {
            /* horaInicio ? 'white' : 'room'"         */
        },
        style() {
            return {
                background: this.horaInicio ? 'linear-gradient(to bottom, #ed4077 79%,#ed4077 100%,#ed4077 100%)' : '#03A9F4',
                cursor: this.horaInicio ? 'pointer' : ''
            }
        },
        classText() {
            /* return this.horaInicio ? "white--text" : "grey-darken-3--text "; */
            return this.horaInicio ? 'white--text' : 'white--text '
        }
    },
    methods: {
        async updateModal() {
            if (this.horaInicio) {
                // //console.log(this.itemFound);

                //console.log('patchVenta')
                await this.$store.dispatch('ventas/selectReserva', {
                    ...this.itemFound,
                    id: this.itemFound.id_reserva
                })

                this.$store.commit('ventas/SHOW_MODAL', {
                    step: 2,
                    state: true
                })
            }
        }
    }
}
</script>
<style>
.mainApp {
    display: flex;
    border: 0px solid #000000;

    padding: 2px;
    height: 110px;
    -webkit-box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
}

.mainPrice {
    position: absolute;
    top: 0 !important;
}
</style>
